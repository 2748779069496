
import React, { useState } from "react";
import thankimage from "../images/thankyou image.png";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";



function ThankYouPage() {

   const [showThankImage, setShowThankImage] = useState(false);
   const navigate = useNavigate();




  return (
    <Card>
      <img className="thankyouimage" src={thankimage} alt="Thank You" />
      <div className="colorful-line"></div>
    </Card>
  );
}
export default ThankYouPage;