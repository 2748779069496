import React, { useState } from "react";
import Contactimg1 from "../../new_Images/OtherComponents/44.png";
import joincomunity from "../../new_Images/OtherComponents/BottomBanner.png";
import "../../App.css";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CommanComponentContact from "./CommanComponentContact";

function ContactUs() {
  const backgroundImage12 = {
    backgroundImage: `url(${joincomunity})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "25vh",
  
    marginTop: "10px",
  };

  return (
    <>
      <div className="">
        <img
          src={Contactimg1}
          className="img-fluid d-block mx-auto homebanner"
          alt="Responsive Image"
        />
      </div>

      <br />
      <br />

      <div className="container">
        <div className="col-md-12 d-flex align-items-center">
          <div className="HomeMission">
            <h3>
              <strong>Having doubts ? Feel free to Ask!!</strong>
            </h3>

            <br />
            <h5 className="contactheading">
              <strong>
                <i>Thank you for your interest in our services!!!</i>
              </strong>
            </h5>
            <p className="mbr-text mbr-fonts-style display-7">
              <i>
                If you have any questions or queries a member of staff will
                always be happy to help. Feel free to contact us by telephone or
                email and so That way we have your contact information and can
                easily get back to you within a business day to schedule an
                introductory call. We will be sure to get back to you as soon as
                possible.
              </i>
            </p>
            <br />
          </div>
        </div>
      </div>

      {/* New current */}
      <div>
      <CommanComponentContact/>
      </div>
      <br />

      {/* <br /> */}

      <div className="container-row" style={backgroundImage12}>
        <div className="col-12 text-center">
          <h1 style={{ color: "white", fontSize: "50px" }}>
            Join our community on Clota Forums
          </h1>

          <br />
          <p style={{ color: "white", fontSize: "22px" }}>
            We Design and Develop Experiences That Makes People Lives Simple.
          </p>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}
        >
          <Link
            to="https://clotat.blogspot.com/"
            style={{ textDecoration: "none" }}
          >
            <button className="btn-styles btn btn-info">
              Connect Now
            </button>
          </Link>
        </div>
      </div>
      <br />
    </>
  );
}

export default ContactUs;
