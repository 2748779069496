import React, { useState } from "react";
import missionimg from "../images/keyboard-5017973-1920-1920x1248 N.jpg";
import Tickicon from "../images/TickIcon.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { TbWorldWww } from "react-icons/tb";
import "../../App.css";

import homeB9 from "../../new_Images/Home Page/Food Promotion Eat Better Minimalis Banner Landscape.png";
import missionAndVission from "../../new_Images/Home Page/Selected One.jpg";
import { Dropdown } from "primereact/dropdown";

import { IoShieldCheckmarkSharp } from "react-icons/io5";

import DatabaseImg from "../../new_Images/Home Page/database.png";
import WebDevelopmentimG from "../../new_Images/Home Page/development-gbf6798523-1921 copy.png";
import databaseimg1N from "../../new_Images/Home Page/database.png";
import cloudimg from "../../new_Images/Home Page/cloud.jpg";
import Additionalimg1 from "../../new_Images/Home Page/additional.png";
import AdditionalimG from "../../new_Images/Home Page/ADDITIONAL SERVICE.png";
import CommanComponentContact from "./CommanComponentContact";

function Home() {
  const [contactName, setContactName] = useState(null);
  const [contactEmail, setContactEmail] = useState("");
  const [contactSubject, setContactSubject] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [otherSubject, setOtherSubject] = useState("");

  const [activelink, setActivelink] = useState("link1");
  const [component1, setComponent1] = useState(true);
  const [component2, setComponent2] = useState(false);
  const [component3, setComponent3] = useState(false);
  const [component4, setComponent4] = useState(false);

  const handleClick = (link) => {
    setActivelink(link);
    setComponent1(link === "link1");
    setComponent2(link === "link2");
    setComponent3(link === "link3");
    setComponent4(link === "link4");
  };

  function ComponentOne(props) {
    return (
      <div
        className="costom-clota-card"
        style={{ display: props.isVisible ? "block" : "none" }}>
        <div className="homeServicesParent ">
          <div className="homeServicesText m-5">
            <i>
              <p>
                We design and construct websites that are effective business
                channels that interact with the target audience, using elegant
                aesthetics and out-of-the-box design concepts. Our websites are
                simple, straightforward and tailored to particular requirements.
              </p>
            </i>
            <button
              type="button"
              className="btn-styles btn btn-info"
              onClick={() => {
                navigate(`/WebDevelopment`);
                scrollToTop();
              }}
              style={{ width: "155px" }}>
              More Details
            </button>
          </div>

          <div className="homeServicesImg">
            <img
              src={WebDevelopmentimG}
              alt="Description of image"
              className="img-fluid"
              width="250px"
            />
          </div>
        </div>
      </div>
    );
  }

  function ComponentTwo(props) {
    return (
      <div
        className="costom-clota-card"
        style={{ display: props.isVisible ? "block" : "none" }}>
        {/* <div className="container"> */}
        <div className="homeServicesParent">
          <div className="homeServicesText m-5">
            <i>
              <p>
                Database Services protects and monitors customer databases by
                establishing backup and recovery procedures, providing a secure
                database environment and monitoring database performance.
              </p>
            </i>
            <button
              type="button"
              className="btn-styles btn btn-info"
              onClick={() => {
                navigate(`/DatabaseServices`);
                scrollToTop();
              }}
              style={{ width: "165px" }}>
              More Details
            </button>
          </div>

          <div className="homeServicesImg">
            <img
              src={databaseimg1N}
              alt="Description of image"
              className="img-fluid mt-4"
              width="500px"
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }

  function ComponentThree(props) {
    return (
      <div
        className="costom-clota-card"
        style={{ display: props.isVisible ? "block" : "none" }}>
        {/* <div className="container"> */}
        <div className="homeServicesParent">
          <div className="homeServicesText m-5">
            <i>
              <p>
                Cloud services, hosted by third-party providers and accessed via
                the internet, enable cloud-native applications and flexible
                work. Our web development team creates tailored websites and
                applications. Businesses no longer need to purchase and manage
                hardware, reducing costs and complexity.
              </p>
            </i>
            <button
              type="button"
              className="btn-styles btn btn-info"
              onClick={() => {
                navigate(`/CloudServices`);
                scrollToTop();
              }}
              style={{ width: "165px" }}>
              More Details
            </button>
          </div>

          <div className="homeServicesImg">
            <img
              src={cloudimg}
              alt="Description of image"
              className="img-fluid cloud-srvcs"
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }

  function ComponentFour(props) {
    return (
      <div
        className="costom-clota-card"
        style={{ display: props.isVisible ? "block" : "none" }}>
        {/* <div className="container"> */}
        <div className="homeServicesParent">
          <div className="homeServicesText m-5">
            <i>
              <p>
                Software tools optimize business processes, document
                development, and improve project productivity. Our software tool
                development approach focuses on meeting company expectations,
                tool effectiveness, integration, choosing the right environment,
                and minimizing the learning curve.
              </p>
            </i>
            <button
              type="button"
              className="btn-styles btn btn-info"
              onClick={() => {
                navigate(`/AdditionalServices`);
                scrollToTop();
              }}
              style={{ width: "165px" }}>
              More Details
            </button>
          </div>

          <div className="homeServicesImg">
            <img
              src={AdditionalimG}
              alt="Description of image"
              className="img-fluid"
              width="450px"
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }

  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    closeNavbar();
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };

  const backgroundMission1 = {
    backgroundImage: `url(${missionimg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "vh",
    width: "100vw",
    // marginTop:'10px'
  };

  const inlineStyles = {
    fontFamily: "Arial",
    fontSize: "32px",

    textalign: "center",
    padding: "20px",
  };

  return (
    <>
      <div className="text-center">
        <img
          src={homeB9}
          className=""
          alt="ResponsiveImage"
          style={{ marginTop: "60px", width: "80%" }}
        />
      </div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <div className="card-body">
                <br />
                <h1
                  className="card-title Text-Gradient"
                  style={{ fontFamily: "rockwell" }}>
                  <b> Why Clota ?</b>
                </h1>
                <p className="card-text">
                  <i>
                    Your one-stop shop for digital solutions and marketing,
                    known for creativity, transparency, and strong work ethics.
                  </i>
                </p>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="card-body">
                <br />
                <h6 className="card-title" style={{ fontFamily: "rockwell" }}>
                  <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />
                  <b>
                    <i>We Follow Proven Processes</i>
                  </b>
                </h6>

                <p className="card-text" style={{ paddingLeft: "50px" }}>
                  <i>
                    We craft strategies to grow your business through innovative
                    web designs, apps and marketing campaigns, positioning your
                    brand for success.
                  </i>
                </p>
              </div>

              <br />
              <div className="card-body">
                <h6 className="card-title" style={{ fontFamily: "rockwell" }}>
                  <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />
                  <b>
                    <i>Research And Support</i>
                  </b>
                </h6>
                <p className="card-text" style={{ paddingLeft: "50px" }}>
                  <i>
                    We deliver top-quality solutions with a simple process and
                    are always available for our clients, making us the best
                    choice.
                  </i>
                </p>
              </div>
              <br />
              <div className="card-body">
                <h6 className="card-title" style={{ fontFamily: "rockwell" }}>
                  <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />
                  <b>
                    <i>We Focus On How It Will Profit Your Business</i>
                  </b>
                </h6>
                <p className="card-text" style={{ paddingLeft: "50px" }}>
                  <i>
                    We focus on customer needs, developing strategies that
                    attract and convert new customers, helping brands make a
                    strong impact and increase revenue.
                  </i>
                </p>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>

      {/* Mission image with title  */}

      <div className="missionVisionBanner">
        <img
          src={missionAndVission}
          // src={missionAndVissionGold}
          className=""
          alt="ResponsiveImage"
          style={{ width: "100%", borderRadius: "20px" }}
        />
      </div>

      {/* Text Vision */}
      <div className="container">
        <h1 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
          <b>Services</b>
        </h1>
        <div className="area m-4">
          <div
            className={`area-left ${
              activelink === "link1" ? "active-link-css" : ""
            }`}
            onClick={() => handleClick("link1")}>
            Web Development
          </div>

          <div
            className={`area-right ${
              activelink === "link2" ? "active-link-css" : ""
            }`}
            onClick={() => handleClick("link2")}>
            Database Services
          </div>
          <div
            className={`area-left ${
              activelink === "link3" ? "active-link-css" : ""
            }`}
            onClick={() => handleClick("link3")}>
            Cloud Services
          </div>
          <div
            className={`area-right ${
              activelink === "link4" ? "active-link-css" : ""
            }`}
            onClick={() => handleClick("link4")}>
            Additional Services
          </div>
        </div>
        <div className="main-section">
          <ComponentOne isVisible={component1} />
          <ComponentTwo isVisible={component2} />
          <ComponentThree isVisible={component3} />
          <ComponentFour isVisible={component4} />
        </div>
      </div>

      <CommanComponentContact />
    </>
  );
}

export default Home;
