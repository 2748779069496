import React, { useRef, useState } from "react";
import Carrers4 from "../images/Careers4.png";
import email from "../images/email.png";
import backimg from "../../new_Images/OtherComponents/42.png"
import CARRERVIDEO from "../../videos/CareerAtClota1.gif";
import Carrersimg from "../../videos/CarrersimgN1.jpg"
import "../../App.css";
import { IoShieldCheckmarkSharp } from "react-icons/io5";

function Careers() {
  
  const mediaRef = useRef();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  

  return (
    <>
      <div className="">
        <img
          src={backimg}
          className="img-fluid d-block mx-auto homebanner" 
          alt="Responsive Image"
        />
      </div>

      
      <div style={{ marginTop: "30px" }}>
        <div className="container">
          <div className="row">
            {/* First Column for Text Content */}
            <div className="col-md-6">
              <h3
                style={{ fontFamily: "rockwell" }}
                className="mt-4 Text-Gradient">
                <b>
                  <i> Career in Clota</i>
                </b>
              </h3>
              {/* <br /> */}
              <div className="homepara">
                <p>
                  <i>
                    <p>
                    At CLOTA, we prioritize getting IT projects right the first time, on time, and within budget.
                     We're growing rapidly and seek passionate, self-motivated individuals.
                     Our organization values both people and clients, ensuring a diverse, inclusive, and accessible workplace.
                    </p>
                    {/* <p>
                    We're rapidly growing and seeking passionate, self-motivated individuals who work and play hard. Our organization values people as much as clients.
                    </p>
                    <p>
                    We seek tech enthusiasts aligned with our work philosophy. Clota ensures a diverse and inclusive workplace, prioritizing accessibility for all.
                    </p> */}
                    {/* <p>
                      <i>
                        In addition to providing information in accessible
                        formats, Clota is dedicated to taking the necessary
                        steps to establish and foster a diverse and adaptable
                        workplace for everyone.
                      </i>
                    </p> */}

                    <p className="mbr-text mbr-fonts-style display-7">
                      <i>
                        Accelerate your career in a high-performance
                        environment.
                      </i>
                    </p>
                    <p className="position mbr-fonts-style display-4">
                      <a
                        href="mailto:support@clotatechnology.com"
                        className="textprimary"
                        style={{ fontSize: "25px" }}>
                        <strong
                          style={{ fontFamily: "rockwell" }}
                          className="Text-Gradient"
                          >

                          Join our alumni circle to stay in touch.
                        </strong>
                      </a>
                    </p>
                  </i>
                </p>
              </div>
              <br />
            </div>

            <div className="col-md-6">
              <div className="feature text-center">
                <div className="image-wrapper text-center ml-3 mr-3">
                  <img
                    src={Carrers4}
                    alt=""
                    className="img-fluid"
                    style={{ marginTop: "7rem" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* This is How Clota Technology help you! */}

        <div className="m-4">
          <h1 className="text-center">
            <strong className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
              <b> This is How Clota Technology Help You!</b>
            </strong>
          </h1>
        </div>

        <br />
        <div className="container">
          <div className="row">
            {/* First Column for Text Content */}
            <div className="col-md-6">
              <h3 className="Text-Gradient" style={{  fontFamily: "rockwell" }}>
                <b>
                  <i>Team Work</i>
                </b>
              </h3>
              <div className="homepara">
                <p>
                  <i>
                    Teamwork makes all kinds of tasks easier and faster. Big or
                    difficult undertakings, on the other hand, necessitate
                    teamwork more than speed or convenience.
                  </i>
                </p>
              </div>
              <br />
              <h3 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
                <b>
                  <i>Unity</i>
                </b>
              </h3>
              <p>
                <i>
                  Unity the word for togetherness or oneness. By being together
                  or at one with someone or something. We Can Achieve Anything!
                </i>
              </p>
              <br />
              <h3 className="Text-Gradient" style={{  fontFamily: "rockwell" }}>
                <b>
                  <i>Get Help</i>
                </b>
              </h3>
              <p>
                <i>
                  Don’t let a career-break break your career. We learn, disrupt,
                  and grow together at Clota! With us, you can explore your
                  career options.
                </i>
              </p>
              <br />
            </div>

            <div className="col-md-6 Carrergif">
              
              <div className="feature text-center webvideoplay">
              <p className="text-center text-white" style={{fontWeight:"500"}}>Hover To Play</p>
    <img
      ref={mediaRef} title="hover to play"
      src={isHovered ? CARRERVIDEO : Carrersimg}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      alt="My Image"
      className="img-fluid mb-3 rounded"
      style={{ height: "50vh", width: "100%" }}
    />
  </div>

            </div>
          </div>
        </div>

        <br />
        <br />

        {/* Advantages of Clota  */}

        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1
                style={{ fontFamily: "rockwell" }}
                className="mt-4 mb-4 Text-Gradient">
                <b>Advantages of Clota</b>
              </h1>

              <i>
                {" "}
                <p className="liststyle">
                  
                  <li>
                  <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />
                    Benefits to Your Career of Working for a Growing
                    Organization.
                  </li>

                  <li>
                  <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />
                    Comprehensive benefits for workers.</li>

                  <li>
                  <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />
                     Diverse and Difficult Projects.</li>

                  <li>
                  <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />
                    Competitive Compensation.</li>

                  <li>
                  <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />
                     Excellent Learning Possibilities.</li>
                </p>
              </i>
            </div>
            <div className="col-md-6 mt-5">
              <img
                src={email}
                alt=""
                className="rounded mx-auto d-block"
                style={{ maxHeight: "190px" }}
                
              />
            </div>
          </div>
        </div>
        <br />
        <div className="container text-center mt-3 mb-4">
          <a
            href="mailto:support@clotatechnology.com"
            target="_Clota"
            className="btn-styles btn btn-info">
            <strong
           
              style={{
               
                fontFamily: "rockwell",
                fontSize: "30px",
              }}>
              Apply Now
            </strong>
          </a>
        </div>

        {/* </div> */}
        {/* </div> */}
        <br />
      </div>
    </>
  );
}

export default Careers;
