import React from "react";
import databaseimg1N from "../../new_Images/OtherComponents/47.png";
import Fulldatabase from "../images/db1.png";
import datamigration from "../images/data-migration.png";
import dataoptimiztion from "../images/data3.png";
import moniteringimg from "../images/Applica.png";
import supportimg from "../images/support.png";
import HighAvailablityimg from "../../new_Images/OtherComponents/HighAvaibility.png";
import DisasterRecoveryimg from "../images/azure-db.png";
import databaseimg from "../../new_Images/OtherComponents/BottomBannerDBsrvc.png";

import Dbimg1 from "../images/Dbimg1.jpg";
import Dbimg2 from "../images/Dbimg2.jpg";
import Dbimg3 from "../images/OracleN-removebg-preview.png";
import Dbimg4 from "../images/Dbimg4.png";
import Dbimg5 from "../images/Dbimg5.png";
import Dbimg6 from "../images/MariyaN.png";
import { IoShieldCheckmarkSharp } from "react-icons/io5";

function DatabaseServices() {
  const backgroundImgdata2 = {
    backgroundImage: `url(${databaseimg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "40vh",
    // width: "100vw",
    marginTop: "50px",
  };

  return (
    <>
      <div className="">
          <img
            src={databaseimg1N}
            className="img-fluid d-block mx-auto homebanner"
            alt="Responsive Image"
          />
        </div>

      <div className="container">
        <h2 className="head-1" style={{}}>
          <br />
          <hr></hr>
          <div className="Text-Gradient" style={{ textAlign: "center",fontFamily: "rockwell" }}>
            <b>
              
                It's our passion for Database technology , which lead us here.
              
            </b>
          </div>
          <hr></hr>
        </h2>
      </div>

      {/* Database Services */}

      <div style={backgroundImgdata2}>
         
        <div className="container databaseservices">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
          
              
              <div className="card-body">
                <br />
                <h1
                  className="card-title Text-Gradient"
                  style={{ fontFamily: "rockwell" }}>
                  <b>Database Services</b>
                </h1>
                <p className="card-text">
                  <i>
                  Database Services ensure database security, performance, and reliability, 
                  focusing on configuration, uptime, and cost-effectiveness.
                  </i>
                </p>
              </div>
            
            </div>
            <div className="col-md-6 ">
            
              
              <div className="card-body">
                <br />
                <h5
                  className="card-title Text-Gradient"
                  style={{ fontFamily: "rockwell" }}>
                  <b><i>Database</i></b>
                </h5>
                <p className="card-text">
                  <i>
                    We do Entire Life Cycle Support from database development to
                    DBA support.
                  </i>
                </p>
              </div>
            
              <br />
              <div className="card-body">
                <h5
                  className="card-title Text-Gradient"
                  style={{fontFamily: "rockwell" }}>
                  
                  <b><i>Services</i></b>
                </h5>
                <p className="card-text">
                  <i>
                    We are expert in implementing database security, best
                    practices and performance tuning.
                  </i>
                </p>
              </div>
              <br />
              <div className="card-body">
                <h5
                  className="card-title Text-Gradient"
                  style={{ fontFamily: "rockwell" }}>
                  <b><i>Customer Support</i></b>
                </h5>
                <p className="card-text">
                  <i>
                    Expert in Cloud DB Migrating and implementation of high
                    availability. Custom Database monitoring and alerting as per
                    need.
                  </i>
                </p>
              </div>
              <br />
            </div>
          </div>
        </div> 
      </div>
      <br />

      {/* Full DBA Services */}

      <div className="container">
        <div className="row">
          <div className="col-md-6" style={{}}>
            <br />
            <h1 className="Text-Gradient" style={{  fontFamily: "rockwell" }}>
             <b>Full DBA Services</b>
            </h1>
            
            <div className="mbr-text mbr-fonts-style display-7 liststyle">
              <i>
                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> 24*7 Production Assistance</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Adaptive database surveillance</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Services for maintaining databases</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Update services for databases</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Managing incidents and maintenance</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Boosted business productivity.</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Connect to other applications.</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Reporting using databases and systems</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Packs of updates and urgent fixes</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Management of space</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Database backup administration</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Configuration and installation</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Monitoring and adjusting SQL performance</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Services for patching databases</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Disaster recovery evaluation and analysis</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Restorations and backups</li>
                

                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Alternatives with high availability</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Administration and upkeep of security</li>
                
              </i>
            </div>
            <br />
          </div>

          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
            <br />
            <br />

            <img
              src={Fulldatabase}
              alt=""
              className="img-fluid"
              style={{ width: "700px", height: "470px" }}
            />
            <br />
            {/* <h5 className="text-center">Full DBA Services</h5> */}
          </div>
        </div>
      </div>

      {/* Migration and Performance Optimization */}

      <div className="container text-center Text-Gradient" style={{  fontFamily: "rockwell" }}>
        <h1 className="m-5">
         <b> Migration and Performance Optimization</b>
        </h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column align-items-center">
            <br />

            <img
              src={datamigration}
              alt=""
              className="img-fluid"
              style={{ width: "400px", height: "300px" }}
            />
            <br />
          </div>

          <div className="col-md-6" style={{}}>
            <br />
            <h4 className="Text-Gradient" style={{fontFamily: "rockwell" }}>
              <b><i>Migration</i></b>
            </h4>
            {/* <br /> */}
            <p>
              <i>
              Database migration is the deployment of 
              a service to move data from source to target databases, ensuring complete transfer with potential reorganization.
              </i>
              <br />
            </p>
          </div>
        </div>
      </div>
      <br />

      {/*  */}

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h4 className='mt-4 Text-Gradient' style={{fontFamily: "rockwell" }}>
              <b><i>Performance Optimization</i></b>{" "}
            </h4>
            
            <p>
              <i>
              SQL tuning accelerates server performance by optimizing SQL queries, 
              aiming to reduce response times and resource consumption for users.
              </i>
            </p>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img
              src={dataoptimiztion}
              alt=""
              className="rounded-circle img-fluid mt-5"
              style={{ width: "370px", height: "330px" }}
            />
          </div>
        </div>
      </div>
      <br />

      {/* Monitoring and Support */}
      <div className="container text-center">
        <h1  style={{  fontFamily: "rockwell" }} className="m-5 Text-Gradient">
          <b>Monitoring and Support</b>
        </h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            
            <h4 style={{  fontFamily: "rockwell" }} className="mt-2 mb-2 Text-Gradient">
             <b><i>Monitoring-</i></b> 
            </h4>
            {/* <br /> */}
            <div className="mbr-text mbr-fonts-style display-7 liststyle">
              <i>
                {" "}
                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Identification of Thresholds Exceeded</li>
                
                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Capacity planning and growth projections</li>
                
                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Capacity planning and growth projections</li>
                
                
                  <li>
                  <IoShieldCheckmarkSharp className="missionVisionBanner-icon" /> Recommendation for resolving limitations in performance
                  </li>
                
                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />OS Configuration</li>
                
                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Disk Configuration</li>
                
                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Database Configuration</li>
                
                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Missing Index</li>
                
                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Unused Indexes</li>
                
                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Review of maintenance jobs</li>
                
                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Review of Database and Server Event Logs</li>
                
                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />OS and Database Performance Base Line.</li>
                
              </i>
            </div>
            <br />
          </div>

          <div className="col-md-6 d-flex flex-column align-items-center">
            <br />
            

            <img
              src={moniteringimg}
              alt=""
              className="img-fluid"
              style={{ width: "500px", height: "300px" }}
            />
            <br />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column align-items-center">
            <img
              src={supportimg}
              alt=""
              className="img-fluid mt-5"
              style={{ width: "400px", height: "220px" }}
            />
            <br />
          </div>
          <div className="col-md-6">
            <br />
            <h4 style={{ fontFamily: "rockwell" }} className="mt-5 Text-Gradient">
              <b><i>Support</i></b>
            </h4>
            {/* <br /> */}
            <div className="mbr-text mbr-fonts-style display-7 liststyle ">
              <i>
                
                  <li>               <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />DB Server Installation and Configuration.</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Disaster Recovery and DR Site.</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Performance Tuning and Resource Optimization.</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Upgrade and Migration.</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Round-the-clock Monitoring.</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />On-Call DBA Support.</li>
                
              </i>
            </div>
          </div>
        </div>
      </div>

      {/* High Availability And Disaster Recovery */}
      <div className="container text-center Text-Gradient" style={{fontFamily: "rockwell" }}>
        <h1 className="m-5">
          <b>High Availability And Disaster Recovery</b>
        </h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <br />
            <h4 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
             <b><i> High Availability</i></b>
            </h4>
            {/* <br /> */}
            <div className="mbr-text mbr-fonts-style display-7 liststyle">
              <p>
                <i>
                High availability ensures continuous service using redundant components during outages. 
                In MS SQL Server, five options for high availability are [mention the five choices].
                </i>
              </p>
              {/* <p>
                <i>
                  In MS SQL Server, there are primarily five choices for
                  configuring high availability solutions for databases:
                </i>
              </p> */}
              <i>
                
                  <li >              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Log Shipping</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Replication</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Mirroring</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Clustering (Failover Cluster)</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Always ON Availability Groups (AOAG)s.</li>
                
              </i>
            </div>
            <br />
          </div>

          <div className="col-md-6 d-flex flex-column align-items-center">
            <br />
            <br />

            <img
              src={HighAvailablityimg}
              alt=""
              className="img-fluid"
              style={{ width: "450px", height: "300px" }}
            />
            <br />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column align-items-center">
            <br />
            <br />

            <img
              src={DisasterRecoveryimg}
              alt=""
              className="img-fluid"
              style={{ width: "450px", height: "300px" }}
            />
            <br />
          </div>
          <div className="col-md-6">
            <br />
            <h4 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
              <b><i>Disaster Recovery</i></b>
            </h4>
            {/* <br /> */}
            <div className="mbr-text mbr-fonts-style display-7 liststyle">
              <i>
                <p>
                A robust SQL Server disaster recovery plan is crucial 
                for operational continuity and data protection against various factors like power failure, hardware issues, and viruses.
                </p>
              </i>
              <i>
                {/* <p>
                  A SQL Server disaster recovery plan (DRP) is a process for
                  keeping SQL Server operational and preventing data loss
                  following a disaster. Many criteria, such as the sensitivity
                  of the data, the ability to tolerate data loss, the level of
                  availability necessary etc.,must be considered in a strong SQL
                  Server disaster recovery plan. Several options can form the
                  basis plan:
                </p> */}
              </i>
              <i>
                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Log shipping</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Replication</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Failover clustering</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Database mirroring</li>
                

                
                  <li>              <IoShieldCheckmarkSharp className="missionVisionBanner-icon" />Backup and restore</li>
                
              </i>
            </div>
            <br />
          </div>
        </div>
      </div>

      {/* images  */}

      <div className="container">
        <h1
          className="text-center m-5 Text-Gradient"
          style={{ fontFamily: "rockwell" }}>
          <b>Database Technologies</b>
        </h1>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Dbimg1}
                className="card-img-top img-fluid"
                alt="Image 1"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Dbimg2}
                className="card-img-top img-fluid"
                alt="Image 2"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Dbimg3}
                className="card-img-top img-fluid"
                alt="Image 3"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Dbimg4}
                className="card-img-top img-fluid"
                alt="Image 4"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Dbimg5}
                className="card-img-top img-fluid"
                alt="Image 5"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Dbimg6}
                className="card-img-top img-fluid"
                alt="Image 6"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DatabaseServices;
