import React, { useState, useEffect } from "react";
import background2 from "../../new_Images/OtherComponents/WhyClientsChooseUs.png";
import A3Teach1 from "../images/a3-4.png";
import Wylth2 from "../images/wylth.png";
import Cigniti from "../images/Cigniti.png";
import Natinoal from "../images/Natinoal.png";
import DvritTech from "../images/DVRITTechLogoNoBG.png";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../../App.css";
import { Carousel } from "primereact/carousel";
import Ourpartnerimg from "../../new_Images/OtherComponents/Clients.png";

function Clients() {
  const navigate = useNavigate();

  const handleWealthButton = () => {
    const newTab = window.open("https://www.wylth.com/", "_blank");
    if (newTab) {
      newTab.focus();
    }
  };
  const handleAThreeTechButton = () => {
    const newTab = window.open("https://www.a3tech.com/", "_blank");
    if (newTab) {
      newTab.focus();
    }
  };
  const handleCignityButton = () => {
    const newTab = window.open("https://www.cigniti.com/", "_blank");
    if (newTab) {
      newTab.focus();
    }
  };
  const handleJncbButton = () => {
    const newTab = window.open("https://www.jncb.com/", "_blank");
    if (newTab) {
      newTab.focus();
    }
  };

  const handleDvritButton = () => {
    const newTab = window.open("https://dvrittech.co.uk/", "_blank");
    if (newTab) {
      newTab.focus();
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  const renderTooltip1 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <p style={{ color: "white" }}>
          <i>
            Customers choose us for our quality, affordability, and constant
            accessibility.
          </i>
        </p>
        <p style={{ color: "white" }}>
          <i>
            We're available via phone, email, or text for any urgent needs or
            progress updates you may require.
          </i>
        </p>
      </div>
    </Tooltip>
  );

  const renderTooltip2 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <p style={{ color: "white" }}>
          <i>We follow a meticulous process to ensure top-quality projects.</i>
        </p>
        <p style={{ color: "white" }}>
          <i>
            Our team of designers, programmers, and project managers will
            collaborate with you to transform your idea into a tailored product.
          </i>
        </p>
      </div>
    </Tooltip>
  );

  const renderTooltip3 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <p style={{ color: "white" }}>
          <i>
            We create customer-focused strategies to attract and convert new
            clients.
          </i>
        </p>
        <p style={{ color: "white" }}>
          <i>
            We create effective tactics that drive impact and increase revenue
            for brands.
          </i>
        </p>
      </div>
    </Tooltip>
  );

  const renderTooltip4 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <p style={{ color: "white" }}>
          <i>
            We told you that we have some of the brightest minds working with
            Clota.
          </i>
        </p>
        <p style={{ color: "white" }}>
          <i>
            We design websites, code apps, and run campaigns efficiently, always
            meeting deadlines without exceeding your budget.
          </i>
        </p>
      </div>
    </Tooltip>
  );

  // const isMobile = window.innerWidth < 767;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Adjust the width as needed for mobile
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on initial load

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const backgroundImageStyle = {
    backgroundImage: `url(${background2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    marginTop: "35px",
    padding: "41px 0px",
    backgroundColor: "rgba(64, 3, 35, 0.8)",
  };

  return (
    <>
      <div className="">
        <img
          src={Ourpartnerimg}
          className="img-fluid d-block mx-auto homebanner"
          alt="Responsive Image"
        />
      </div>
      <br />

      <div>
        {/* Latest Code */}
        <div className="container ourClients">
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-6 col-md-6 col-lg-4 clientcard1 card-hover-effect p-3">
              <div className="position-relative">
                <div className="text-Clients" style={{ color: "black" }}>
                  <img
                    src={A3Teach1}
                    className="rounded card-img-top"
                    alt="..."
                    style={{ height: "120px" }}
                  />
                  <div className="card-body">
                    <br />
                    <p className="card-text">
                      <i>
                        Financial advisors with passion, hunger, and drive are
                        our partners. We offer the most complete Platform as a
                        Service (PaaS) for financial advisers, encompassing the
                        whole client journey.
                        <br />
                        <br />
                        A3Tech Pte Ltd 60 Albert Street, #14-01 OG Albert
                        Complex, Singapore 189969 info@a3tech.com
                      </i>
                    </p>

                    <div className="text-center btn1">
                      <button
                        className="btn-styles btn btn-info w-75"
                        onClick={handleAThreeTechButton}>
                        Visit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 clientcard1 card-hover-effect ">
              <div className="position-relative">
                <div className="text-Clients" style={{ color: "black" }}>
                  <img
                    src={Wylth2}
                    className="rounded card-img-top"
                    alt="..."
                    style={{ height: "120px" }}
                  />
                  <div className="card-body">
                    <br />
                    <p className="card-text mt-1">
                      <i>
                        Wylth® is a unified multi-asset platform that aims to
                        serve all the needs of an Independent Financial Advisor
                        / Mutual Fund Distributor.
                        <br />
                        <br />
                        Wylth® Singapore 60 Albert Street, #14-01 OG Albert
                        Complex, Singapore 189969 contact@wylth.com
                      </i>
                    </p>{" "}
                    <br />
                    <div className="text-center btn2">
                      <button
                        className="btn-styles btn btn-info w-75"
                        onClick={handleWealthButton}>
                        Visit
                      </button>
                      <div className="mb-3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4 clientcard1 card-hover-effect ">
              <div className="position-relative">
                <div className="text-Clients" style={{ color: "black" }}>
                  <img
                    src={Cigniti}
                    className="rounded card-img-top"
                    alt="..."
                    style={{ height: "120px" }}
                  />
                  <div className="card-body">
                    <br />
                    <p className="card-text">
                      <i>
                        Cigniti Technologies is a global leader in providing
                        AI-driven, IP-led strategic digital assurance, software
                        quality engineering, software testing, automation and
                        consulting services.
                        <br />
                        6th Floor, Orion Block, International Tech Park, Plot
                        No. 17, Software Units Layout, Madhapur, Hyderabad,
                        India – 500081 +91 40 4038 2255
                      </i>
                    </p>

                    <div className="text-center mt-auto btn3">
                      <button
                        className="btn-styles btn btn-info w-75"
                        onClick={handleCignityButton}>
                        Visit
                      </button>
                      <div className="mb-3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-6 col-md-6 col-lg-3 clientcard1 card-hover-effect p-3">
              <div className="position-relative">
                <div className="text-Clients" style={{ color: "black" }}>
                  <img
                    src={Natinoal}
                    className="rounded card-img-top"
                    alt="..."
                    style={{ height: "120px" }}
                  />
                  <div className="card-body d-flex flex-column">
                    <br />
                    <p className="card-text">
                      <i>
                        National Commercial Bank Jamaica Limited offers a wide
                        range of goods and services to fulfill its customers'
                        banking needs, including deposit accounts, unsecured and
                        secured loans, credit cards, overdraft lines, foreign
                        currency, personal and private banking services.
                        <br />
                        <br />
                        32 Trafalgar Road, Kingston 10 Jamaica W.I
                      </i>
                    </p>

                    <div className="text-center mt-auto btn4">
                      <button
                        className="btn-styles btn btn-info w-75"
                        onClick={handleJncbButton}>
                        Visit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-3 clientcard1 card-hover-effect p-3">
              <div className="position-relative">
                <div className="text-Clients" style={{ color: "black" }}>
                  <img
                    src={DvritTech}
                    className="rounded card-img-top shadow-2"
                    alt="..."
                    style={{ height: "120px" }}
                  />
                  <div className="card-body d-flex flex-column">
                    <br />
                    <p className="card-text">
                      <i>
                        DVRIT Tech is an organization that brings together some
                        of the brightest minds in the world. Its motto is
                        "inventing advanced technologies & innovating them
                        further to invent more.
                        <br />
                        <br />
                        16 Commerce Square Lace Market Nottingham NG1 1HS +44
                        7555 995630 info@dvrittech.co.uk
                      </i>
                    </p>
                    <br />

                    <div className="text-center mt-auto btn4">
                      <button
                        className="btn-styles btn btn-info w-75"
                        onClick={handleDvritButton}>
                        Visit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Reasonsbox">
        <div className="feature text-center" style={backgroundImageStyle}>
          <div className="container">
            <div className="row">
              <h1 style={{ color: "#01579b", fontFamily: "rockwell" }}>
                <b>REASONS WHY CLIENTS CHOOSE US!</b>
              </h1>
              <br />
              <br />

              <div className="col-md-3">
                <OverlayTrigger
                  placement={isMobile ? "top" : "right"}
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip1}>
                  <Button
                    className="btn-styles btn btn-info boxclient btn-block mb-2"
                    style={{
                      fontFamily: "rockwell",

                      height: "80%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    WE ARE ALWAYS AVAILABLE FOR YOU
                  </Button>
                </OverlayTrigger>
                <br />
                <br />
              </div>

              <div className="col-md-3">
                <OverlayTrigger
                  placement={isMobile ? "top" : "right"}
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip2}>
                  <Button
                    className="btn-styles btn btn-info  boxclient btn-block mb-2"
                    style={{
                      fontFamily: "rockwell",

                      height: "80%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    WE FOLLOW PROVEN PROCESSES
                  </Button>
                </OverlayTrigger>
                <br />
                <br />
              </div>

              <div className="col-md-3">
                <OverlayTrigger
                  placement={isMobile ? "top" : "left"}
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip3}>
                  <Button
                    className="btn-styles btn btn-info btn-block mb-2"
                    style={{
                      fontFamily: "rockwell",

                      color: "black",
                      height: "80%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    WE FOCUS ON HOW IT WILL PROFIT YOUR BUSINESS
                  </Button>
                </OverlayTrigger>
                <br />
                <br />
              </div>

              <div className="col-md-3">
                <OverlayTrigger
                  placement={isMobile ? "top" : "left"}
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip4}>
                  <Button
                    className="btn-styles btn btn-info  boxclient btn-block mb-2"
                    style={{
                      fontFamily: "rockwell",

                      height: "80%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    WE DELIVER ON TIME ON BUDGET
                  </Button>
                </OverlayTrigger>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="text-center">
          <div className="body-card" style={{ marginTop: "50px" }}>
            <h1 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
              <b> What Clients Says!!</b>
            </h1>
            <br />
          </div>
        </div>
      </div>

      <div className="container shadow-lg p-4" style={{ borderRadius: "30px" }}>
        <div className="row">
          <div className="col-md-6 mt-2 mb-2 d-flex justify-content-center">
            <img
              src={Wylth2}
              className="rounded card-img-top"
              alt="..."
              style={{ height: "120px", width: "300px" }}
            />
          </div>

          <div className="col-md-6">
            <p>
              <i>
                We began working with Clota Technologies since Feb 2022
                Fantastic Database Support Partner, Trustworthy, highly skilled
                and experienced team members. We've relied on them exclusively
                since we onboarded them and look forward to continuing that
                relationship. I sleep better knowing Clota has got our backs
                24/7/365
              </i>
              <br />
              <br />
            </p>

            <p className="Text-Gradient">
              <b>
                <i>
                  Siddhesh Sawant
                  <br />
                  Client
                </i>
              </b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clients;
