import React from "react";
import ClotaLogoHD from "../images/ClotaHDLogoRound.png";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => (
  <div className="container-fluid  ">
    <footer id="footer">
      <div className="row ">
        <div className="col-md mt-3 d-flex flex-column align-items-center">
          <img
            src={ClotaLogoHD}
            alt="clotalogo"
            height="130"
            width="170"
            className="mx-auto, ms-3 img-fluid"
            style={{ maxWidth: "100%", height: "auto", marginBottom: "1rem" }}
          />
          <br />
        </div>

        <div className="col-md sections justify-content-center text-center ">
          <ul className="AboutClota">
            <div className="col-md mt-3">
              <h5> About Clota</h5>
            </div>
            <p>
              Over a decade of experience and implementing best solutions in
              Database Technologies and Web Development. It's our passion for
              technologies which lead us here.
            </p>
          </ul>
        </div>
        <div className="col-md sections2 justify-content-center text-center">
          <ul>
            <div className="col-md mt-3">
              <h5>Clota Help</h5>
            </div>
            <h5>
              <Link
                to="https://clotat.blogspot.com/"
                target="_clota"
                
                className="Text-Gradient"
                >
                
                Clota form
              </Link>
            </h5>
            <h5>
              <Link
                to="/ContactUsForm"
                target="_clota"
                 className="Text-Gradient">
                Help center
              </Link>
            </h5>
          </ul>
        </div>

        <div className="col-md sections justify-content-center text-center">
          <ul>
            <div className="col-md mt-3">
              <h5>Clota Community</h5>
            </div>
            <h5  className="Text-Gradient">
              <Link
                to="https://clotat.blogspot.com/"
                target="_clota"
                 className="Text-Gradient">
                Clota Blog
              </Link>
            </h5>
            <h5>
              <Link
                to="/ContactUsForm"
                target="_clota"
                 className="Text-Gradient">
                Quick Connect
              </Link>
            </h5>
            <h5>
              <Link
                to="/ContactUsForm"
                target="_clota"
                 className="Text-Gradient">
                Having Quaries talk to us
              </Link>
            </h5>
          </ul>
        </div>
      </div>
      <div className="col-12 text-center">
        <div
          className="card"
          style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
          <div className="card-body">
            <ul className="list-unstyled" style={{ color: "white" }}>
              <h4  className="Text-Gradient"> Follow Us On</h4>
              <div style={{ fontSize: "25px" }}>
                <Link
                  to="https://www.facebook.com/ClotaTech"
                  target="_clota"
                  className="d-inline-block p-3 Social-icons">
                  <i
                    className="fa-brands fa-facebook iconFacebook"
                    style={{ color: " #1877f2" }}></i>
                </Link>
                <Link
                  to="https://www.instagram.com/ClotaTech"
                  target="_clota"
                  className="d-inline-block p-3 Social-icons">
                  <i
                    className="fab fa-instagram iconInstagram"
                    style={{ color: "#bc2a8d" }}></i>
                </Link>
                <Link
                  to="https://twitter.com/ClotaTech"
                  target="_clota"
                  className="d-inline-block p-3 Social-icons"
                  style={{ color: "black" }}>
                  <FaSquareXTwitter />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/clota-tech/"
                  target="_clota"
                  className="d-inline-block p-3 Social-icons">
                  <i
                    className="fab fa-linkedin iconLinkedin"
                    style={{ color: "#0077B5" }}></i>
                </Link>
                <Link
                  to="https://api.whatsapp.com/send?phone=919490384177&text=hi i am <EnterYourName> and i'm enquiriying through your Clotatech Site"
                  target="_clota"
                  className="d-inline-block p-3 Social-icons">
                  <i
                    className="fa-brands fa-whatsapp iconWhatsapp"
                    style={{ color: "#25d366" }}></i>
                </Link>
              </div>
            </ul>
            <div style={{ fontSize: "18px" }} className="Text-Gradient">
              © Copyright 2024 Clota Technology - All Rights Reserved
            </div>
          </div>
        </div>

        <br />
      </div>
    </footer>
  </div>
);

export default Footer;
